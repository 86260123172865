// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-colabora-js": () => import("./../../../src/pages/colabora.js" /* webpackChunkName: "component---src-pages-colabora-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plans-js": () => import("./../../../src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-professional-form-js": () => import("./../../../src/pages/professional-form.js" /* webpackChunkName: "component---src-pages-professional-form-js" */),
  "component---src-pages-professional-js": () => import("./../../../src/pages/professional.js" /* webpackChunkName: "component---src-pages-professional-js" */),
  "component---src-pages-specialties-js": () => import("./../../../src/pages/ specialties.js" /* webpackChunkName: "component---src-pages-specialties-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-specialization-template-js": () => import("./../../../src/templates/SpecializationTemplate.js" /* webpackChunkName: "component---src-templates-specialization-template-js" */)
}

